import { Avatar, Flex, Stack } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

import { Footer } from "../../components/templates/Footer";
import { useStoreClient } from "../../hooks/storeClient";
import { api } from "../../services/api";

interface AdminProps {
  children?: ReactNode;
}

export function StoreClientTemplate({ children }: AdminProps) {
  const { company, whiteLabel, feature, updateWhiteLabel } = useStoreClient();
  const [logo, setLogo] = useState<string>("");
  useEffect(() => {
    if (company.logo) {
      api.get(`/companies/logo/${company.logo}`).then((response) => {
        setLogo(response.data);
      });
    }
  }, [company.logo]);

  useEffect(() => {
    if (feature.facebook_pixel) {
      ReactPixel.init(feature.facebook_pixel);
      ReactPixel.pageView();
    }
    if (feature.google_tag_manager) {
      TagManager.initialize({ gtmId: feature.google_tag_manager });
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: window.location.pathname,
        },
      });
    }

    if (feature.google_analytics) {
      ReactGA.initialize(feature.google_analytics, {
        gaOptions: { name: "Dynamic" },
      });
      ReactGA.ga(
        "Dynamic.send",
        "pageview",
        window.location.pathname + window.location.search
      );
    }
  }, [
    feature.facebook_pixel,
    feature.google_analytics,
    feature.google_tag_manager,
  ]);

  useEffect(() => {
    if (
      window.location.origin !== process.env.REACT_APP_WEB_URL &&
      window.location.origin !== process.env.REACT_APP_OTHER_WEB_URL
    ) {
      api
        .get(`/whiteLabels/formDomain?form_domain=${window.location.origin}`)
        .then((response) => {
          updateWhiteLabel(response.data);
        });
    }
  }, [updateWhiteLabel]);

  return (
    <>
      {whiteLabel ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Pesquisa - {whiteLabel.name}</title>
          <link rel="icon" href={whiteLabel.favicon_url} />
        </Helmet>
      ) : (
        <Helmet>
          <title>Falaê - Pesquisa</title>
          <link rel="icon" href="/favicon.ico" />
          <link rel="manifest" href="/manifest.json" />
          <link rel="apple-touch-icon" href="logo192.png" />
        </Helmet>
      )}
      <Flex
        flexDir="column"
        justify="space-between"
        h="100vh"
        fontFamily="Mulish"
      >
        <Flex align="center" justify="center">
          <Flex
            h="120px"
            w="100vw"
            bg="white"
            borderRadius="0% 0% 50% 50%"
            boxShadow="0px 10px 12px rgba(0,0,0,0.05)"
            justify="center"
            align="center"
          >
            <Avatar
              src={logo}
              name={company.name}
              loading="lazy"
              size="xl"
              mx="auto"
              bg="transparent"
              borderRadius="50%"
            />
          </Flex>
        </Flex>

        <Flex
          maxW="500px"
          my="5px"
          justify="center"
          mx={{ base: "25px", md: "auto" }}
        >
          <Stack spacing={5} direction="column">
            {children}
          </Stack>
        </Flex>
        <Footer whiteLabel={whiteLabel} />
      </Flex>
    </>
  );
}
