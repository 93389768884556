import { useState } from "react";
import { useParams } from "react-router-dom";

import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { TitleDefault } from "../../../components/atoms/Text/TitleDefault";
import { LoaderClientSearchButtonList } from "../../../components/organisms/LoaderClientSearchButtonList";
import { Loader } from "../../../components/templates/Loader";
import { ClientConfirmationModalSearch } from "../../../components/templates/Modal/ClientConfirmationModalSearch";
import { useFormClient } from "../../../hooks/formClient";

interface LoaderClientOnePageProps {
  name?: string;
  email?: string;
}
export function LoaderClientOnePage({ email, name }: LoaderClientOnePageProps) {
  const { search, company, page, questionQuery, whiteLabel } = useFormClient();
  const [loading, setLoading] = useState(
    search.has_transparency && email && name ? false : true
  );

  const { slug, search_slug, answer_id } = useParams();

  return (
    <>
      {search.has_transparency && email && name && (
        <ClientConfirmationModalSearch
          name={name}
          company={company}
          email={email}
          search={search}
          answer_id={answer_id}
          setLoading={(status: boolean) => setLoading(status)}
        />
      )}
      <Loader isLoading={loading} whiteLabel={whiteLabel}>
        <TitleDefault
          title={
            search.is_partitioned
              ? `Cadastro - ${company.name}`
              : search.title
                ? search.title
                : `Pesquisa de Satisfação - ${company.name}`
          }
        />
        <HTMLText html={search.client_message} />
        <LoaderClientSearchButtonList
          loginLink={`/formularios/${company.slug}/${
            search.slug
          }/cliente/atualizar${answer_id ? "/" + answer_id : ""}`}
          primaryColor={company.color}
          secondaryColor={company.secondary_color}
          thanksLink={`/formularios/${slug}/${search_slug}/obrigado`}
          storeLink={`/formularios/${company.slug}/${
            search.slug
          }/cliente/cadastro${answer_id ? "/" + answer_id : ""}`}
          textColor="#FFFF"
          uniqueAnswerClient={search.unique_answer_client}
          anonymousSearch={search.anonymous_search}
          reverseOrder={search.reverse_order}
          formLink={
            search.has_page
              ? `/formularios/${slug}/${search_slug}/${
                  page ? `${page}?${questionQuery}` : "pagina/inicial"
                }`
              : `/formularios/${slug}/${search_slug}?${questionQuery}`
          }
        />
      </Loader>
    </>
  );
}
