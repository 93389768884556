import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useFormClient } from "../../../hooks/formClient";
import { api } from "../../../services/api";
import { UpdateClientOnePage } from "../SearchOnePage/updateClient";

type EmailFormData = {
  approved: boolean;
  email: string;
  fidelity: boolean;
};

export function IndexUpdateClientControl() {
  const { search, company, page, questionQuery, isTest } = useFormClient();
  const navigate = useNavigate();
  const toast = useToast();
  const { slug, search_slug, answer_id } = useParams();
  const [error, setError] = useState<string>("");
  const handleVisit: SubmitHandler<EmailFormData> = useCallback(
    async (formValue) => {
      setError("");
      try {
        if (!isTest) {
          if (answer_id) {
            await api
              .post("/clientForms/update", {
                company_id: search.company_id,
                email: formValue.email,
                answer_id: answer_id,
                approved: formValue.approved,
                fidelity: formValue.fidelity,
                search_id: search.id,
              })
              .then((response) => {
                localStorage.setItem(
                  `@B2S-${company.slug}:user`,
                  JSON.stringify({
                    name: response.data.name,
                    email: response.data.email,
                    id: response.data.id,
                  })
                );
                if (response.data.completed) {
                  if (search.roulette_id) {
                    navigate(
                      `/formularios/${company.slug}/${search.slug}/roleta/${search.roulette_id}/${response.data.id}`
                    );
                    return;
                  }
                  if (response.data.coupons) {
                    navigate(
                      `/formularios/${slug}/${search_slug}/obrigado/${response.data.coupons[0].id}`
                    );
                  } else {
                    navigate(`/formularios/${slug}/${search_slug}/obrigado`);
                  }
                } else {
                  navigate(
                    `/formularios/${slug}/${search_slug}/cliente/completar/${response.data.id}`
                  );
                }
              });
          } else {
            const response = await api.post("/clientForms/update", {
              company_id: search.company_id,
              email: formValue.email,
              search_id: search.id,
              is_partitioned: search.is_partitioned,
              fidelity: formValue.fidelity,
            });
            if (search.has_transparency) {
              localStorage.setItem(
                `@B2S-${company.slug}:user`,
                JSON.stringify({
                  name: response.data.name,
                  email: response.data.email,
                  id: response.data.id,
                })
              );
            }
            if (search.is_partitioned) {
              navigate(`/formularios/${slug}/${search_slug}/obrigado`);
            } else {
              if (search.has_page) {
                if (page) {
                  navigate(
                    `/formularios/${slug}/${search_slug}/${page}/${response.data.id}?${questionQuery}`
                  );
                } else {
                  navigate(
                    `/formularios/${slug}/${search_slug}/pagina/inicial/${response.data.id}`
                  );
                }
              } else {
                navigate(
                  `/formularios/${slug}/${search_slug}/${response.data.id}?${questionQuery}`
                );
              }
            }
          }
        }
      } catch (err: any) {
        if (err.response && err.response.data) {
          const { data } = err.response;

          if (
            data.validation &&
            data.validation.body &&
            data.validation.body.keys.includes("email")
          ) {
            setError("Formato inválido");
          } else if (
            data.message === "Request failed with status code 404" ||
            data.message === "Client not found"
          ) {
            toast({
              id: "email",
              title: "E-mail não encontrado",
              status: "error",
              duration: 6000,
              isClosable: true,
              position: "top",
            });
            navigate(
              `/formularios/${company.slug}/${search.slug}/cliente/cadastro${
                answer_id ? "/" + answer_id : ""
              }?type=true`
            );
          } else {
            toast({
              title: `Ocorreu um erro. Por favor, tente novamente mais tarde.`,
              position: "top",
              isClosable: true,
              duration: 5000,
              status: "error",
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isTest,
      answer_id,
      search.company_id,
      search.id,
      search.is_partitioned,
      search.has_transparency,
      search.has_page,
      search.slug,
      company.slug,
      navigate,
      slug,
      search_slug,
      page,
      questionQuery,
      toast,
    ]
  );
  switch (search.type_form) {
    case "onepage":
      return <UpdateClientOnePage error={error} handle={handleVisit} />;
    case "multipages":
      return <UpdateClientOnePage error={error} handle={handleVisit} />;
    default:
      return <UpdateClientOnePage error={error} handle={handleVisit} />;
  }
}
