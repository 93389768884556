/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { useFormClient } from "../../../hooks/formClient";
import { useQuery } from "../../../hooks/query";
import { SubmitButton } from "../../atoms/Button/SubmitButton";
import { CheckboxQuestion } from "../../organisms/CheckboxQuestion";
import { DateQuestionDefault } from "../../organisms/DateQuestion/DateQuestionDefault";
import { CSATRadioQuestion } from "../../organisms/RadioQuestion/CSATRadioQuestion";
import { EmoticonRadioQuestion } from "../../organisms/RadioQuestion/EmoticonRadioQuestion";
import { LikeDislikeRadioQuestionDefault } from "../../organisms/RadioQuestion/LikeDislikeRadioQuestion/LikeDislikeRadioQuestionDefault";
import { MultipleChoiceRadioQuestionDefault } from "../../organisms/RadioQuestion/MultipleChoiceRadioQuestion/MultipleChoiceRadioQuestionDefault";
import { NPSRadioQuestionDefault } from "../../organisms/RadioQuestion/NPSRadioQuestion/NPSRadioQuestionDefault";
import { RatingRadioQuestionDefault } from "../../organisms/RadioQuestion/RatingRadioQuestion/RatingRadioQuestionDefault";
import { SelectQuestionDefault } from "../../organisms/SelectQuestion/SelectQuestionDefault";
import { TextQuestionDefault } from "../../organisms/TextQuestion/TextQuestionDefault";
import { NpsAndSuggestion } from "../NpsAndSuggestion";

interface IQuestion {
  id: string;
  name: string;
  nick: string;
  required: boolean;
  others?: boolean;
  unknown?: boolean;
  description: string;
  type_csat?: "numerico" | "satisfeito";
  type:
    | "NPS"
    | "CSAT"
    | "Caixa de seleção"
    | "Multipla escolha"
    | "Rating"
    | "Emoticon"
    | "Like/Dislike"
    | "Resposta curta"
    | "Atendente"
    | "Data";
  options?: {
    id: string;
    name: string;
    created_at: Date;
  }[];
  detractor_replica?: string;
  neutral_replica?: string;
  promoter_replica?: string;
  has_promoter_replica?: boolean;
  has_neutral_replica?: boolean;
  has_detractor_replica?: boolean;
  format: "default" | "select";
}

interface IProps {
  selectColor: string;
  questions: IQuestion[];
  handle: (data: any) => void;
}

export function QuestionSearchOnePage({
  selectColor,
  questions,
  handle,
}: IProps) {
  const query = useQuery();
  const location = useLocation();
  const [countQuestionWithAnswer, setCountQuestionWithAnswer] = useState(0);
  const { register, handleSubmit, setValue, formState } = useForm({});

  useEffect(() => {
    const params = location.search.replace("?", "").split("&");
    let count = 0;
    if (params.length > 0) {
      params.forEach((param) => {
        const [id, value] = param.split("=");

        const index = questions.findIndex((question) => question.id === id);
        if (index !== -1) {
          count++;
          setValue(`quizResponses.${id}`, decodeURIComponent(value));
        }
      });
    }
    setCountQuestionWithAnswer(count);
  }, [countQuestionWithAnswer, location.search, questions, setValue]);
  const { search, company, replaceQuestion } = useFormClient();
  const [defaultPosition, setDefaultPosition] = useState(
    search.has_nps_first ? 2 : 1
  );
  const [responseChannel, setResponseChannel] = useState<string>("");
  const { errors } = formState;
  useEffect(() => {
    const response = localStorage.getItem(`@B2S:link`);
    if (response) {
      setResponseChannel("QRcode");
    } else {
      setResponseChannel("Link");
    }
    setDefaultPosition(search.has_nps_first ? 2 : 1);
  }, [company.slug, responseChannel, search.has_nps_first]);

  return (
    <Flex
      as="form"
      flexDir="column"
      w="100%"
      onSubmit={handleSubmit(handle)}
      id="send-form"
    >
      {search.has_nps_first ? (
        <NpsAndSuggestion
          has_suggestion={false}
          nps_first={search.has_nps_first}
          nps_label={search.nps}
          register={register}
          selectColor={selectColor}
          setNpsValue={(nps: string, value: number) => {
            setValue("nps", value);
          }}
          setSuggestionValue={(suggestion: string) => {
            setValue("discursive_question", suggestion);
          }}
          suggestion_label={search.discursive_question}
          total_questions={questions.length}
        />
      ) : (
        <></>
      )}

      {questions.map((question, index) => {
        switch (question.type) {
          case "NPS":
            return (
              <>
                <NPSRadioQuestionDefault
                  description={question.description}
                  selectColor={selectColor}
                  {...register(`quizResponses.${question.id}`)}
                  label={`${
                    defaultPosition + index - countQuestionWithAnswer
                  } - ${question.name
                    .replace(/ Empresa ]/gi, replaceQuestion)
                    .replace("[", "")}`}
                  setValue={(nps: number, replica: string) => {
                    if (nps === -1) {
                      setValue(`quizResponses.${question.id}`, "");
                    }
                    if (nps !== -1) {
                      setValue(`quizResponses.${question.id}`, nps);
                      setValue(
                        `quizResponses_suggestion.${question.id}`,
                        replica
                      );
                    }
                  }}
                  isRequired={question.required}
                  has_detractor_replica={search.has_detractor_replica}
                  has_neutral_replica={search.has_neutral_replica}
                  has_promoter_replica={search.has_promoter_replica}
                  detractor_replica={question.detractor_replica}
                  neutral_replica={question.neutral_replica}
                  promoter_replica={question.promoter_replica}
                />
              </>
            );
          case "Caixa de seleção":
            return query.get(question.id) !== null ? (
              <></>
            ) : (
              <CheckboxQuestion
                description={question.description}
                selectColor={selectColor}
                label={`${
                  defaultPosition + index - countQuestionWithAnswer
                } - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                {...register(`quizResponses.${question.id}`)}
                onChange={(value: string) => {
                  if (value === "[]") {
                    setValue(`quizResponses.${question.id}`, "");
                  } else {
                    setValue(`quizResponses.${question.id}`, value);
                  }
                }}
                options={
                  question.options
                    ? question.options
                        .sort((a, b) => (a.created_at < b.created_at ? -1 : 1))
                        .map((option) => ({
                          value: option.name,
                          label: option.name,
                        }))
                    : []
                }
                other={question.others}
                isRequired={question.required}
              />
            );

          case "CSAT":
            return (
              <CSATRadioQuestion
                description={question.description}
                selectColor={selectColor}
                label={`${
                  defaultPosition + index - countQuestionWithAnswer
                } - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                {...register(`quizResponses.${question.id}`)}
                isRequired={question.required}
                setValue={(nps: number, replica: string) => {
                  if (nps === -1) {
                    setValue(`quizResponses.${question.id}`, "");
                  }
                  if (nps !== -1) {
                    setValue(`quizResponses.${question.id}`, nps);
                    setValue(
                      `quizResponses_suggestion.${question.id}`,
                      replica
                    );
                  }
                }}
                has_detractor_replica={search.has_detractor_replica}
                has_neutral_replica={search.has_neutral_replica}
                has_promoter_replica={search.has_promoter_replica}
                detractor_replica={question.detractor_replica}
                neutral_replica={question.neutral_replica}
                promoter_replica={question.promoter_replica}
              />
            );
          case "Multipla escolha":
            if (query.get(question.id) !== null) {
              return <></>;
            }
            switch (question.format) {
              case "select":
                return (
                  <SelectQuestionDefault
                    description={question.description}
                    label={`${
                      defaultPosition + index - countQuestionWithAnswer
                    } - ${question.name
                      .replace(/ Empresa ]/gi, replaceQuestion)
                      .replace("[", "")}`}
                    options={
                      question.options
                        ? question.options.sort((a, b) =>
                            a.created_at < b.created_at ? -1 : 1
                          )
                        : []
                    }
                    {...register(`quizResponses.${question.id}`)}
                    isRequired={question.required}
                    setValue={(value: string) => {
                      setValue(`quizResponses.${question.id}`, value);
                    }}
                    selectColor={selectColor}
                    other={question.others}
                  />
                );
              case "default":
                return (
                  <MultipleChoiceRadioQuestionDefault
                    description={question.description}
                    label={`${
                      defaultPosition + index - countQuestionWithAnswer
                    } - ${question.name
                      .replace(/ Empresa ]/gi, replaceQuestion)
                      .replace("[", "")}`}
                    options={
                      question.options
                        ? question.options.sort((a, b) =>
                            a.created_at < b.created_at ? -1 : 1
                          )
                        : []
                    }
                    {...register(`quizResponses.${question.id}`)}
                    isRequired={question.required}
                    setValue={(value: string) => {
                      setValue(`quizResponses.${question.id}`, value);
                    }}
                    selectColor={selectColor}
                    other={question.others}
                  />
                );
              default:
                return <></>;
            }
          case "Atendente":
            if (query.get(question.id) !== null) {
              return <></>;
            }
            switch (question.format) {
              case "select":
                return (
                  <SelectQuestionDefault
                    type="Atendente"
                    description={question.description}
                    label={`${
                      defaultPosition + index - countQuestionWithAnswer
                    } - ${question.name
                      .replace(/ Empresa ]/gi, replaceQuestion)
                      .replace("[", "")}`}
                    options={
                      question.options
                        ? question.options.sort((a, b) =>
                            a.created_at < b.created_at ? -1 : 1
                          )
                        : []
                    }
                    {...register(`quizResponses.${question.id}`)}
                    isRequired={question.required}
                    setValue={(value: string) => {
                      setValue(`quizResponses.${question.id}`, value);
                    }}
                    selectColor={selectColor}
                    unknown={question.unknown}
                    other={question.others}
                  />
                );
              case "default":
                return (
                  <MultipleChoiceRadioQuestionDefault
                    type="Atendente"
                    description={question.description}
                    label={`${
                      defaultPosition + index - countQuestionWithAnswer
                    } - ${question.name
                      .replace(/ Empresa ]/gi, replaceQuestion)
                      .replace("[", "")}`}
                    options={
                      question.options
                        ? question.options.sort((a, b) =>
                            a.created_at < b.created_at ? -1 : 1
                          )
                        : []
                    }
                    {...register(`quizResponses.${question.id}`)}
                    isRequired={question.required}
                    setValue={(value: string) => {
                      setValue(`quizResponses.${question.id}`, value);
                    }}
                    selectColor={selectColor}
                    unknown={question.unknown}
                    other={question.others}
                  />
                );
              default:
                return <></>;
            }
          case "Rating":
            return (
              <RatingRadioQuestionDefault
                description={question.description}
                label={`${
                  defaultPosition + index - countQuestionWithAnswer
                } - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                {...register(`quizResponses.${question.id}`)}
                isRequired={question.required}
                setValue={(value: string, replica: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                  setValue(`quizResponses_suggestion.${question.id}`, replica);
                }}
                has_detractor_replica={search.has_detractor_replica}
                has_neutral_replica={search.has_neutral_replica}
                has_promoter_replica={search.has_promoter_replica}
                detractor_replica={question.detractor_replica}
                neutral_replica={question.neutral_replica}
                promoter_replica={question.promoter_replica}
                selectColor={selectColor}
              />
            );
          case "Emoticon":
            return (
              <EmoticonRadioQuestion
                description={question.description}
                label={`${
                  defaultPosition + index - countQuestionWithAnswer
                } - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                {...register(`quizResponses.${question.id}`)}
                isRequired={question.required}
                setValue={(value: string, replica: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                  setValue(`quizResponses_suggestion.${question.id}`, replica);
                }}
                has_detractor_replica={search.has_detractor_replica}
                has_neutral_replica={search.has_neutral_replica}
                has_promoter_replica={search.has_promoter_replica}
                detractor_replica={question.detractor_replica}
                neutral_replica={question.neutral_replica}
                promoter_replica={question.promoter_replica}
                selectColor={selectColor}
              />
            );
          case "Like/Dislike":
            return (
              <LikeDislikeRadioQuestionDefault
                selectColor={selectColor}
                description={question.description}
                label={`${
                  defaultPosition + index - countQuestionWithAnswer
                } - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                {...register(`quizResponses.${question.id}`)}
                isRequired={question.required}
                setValue={(value: string, replica: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                  setValue(`quizResponses_suggestion.${question.id}`, replica);
                }}
                has_detractor_replica={search.has_detractor_replica}
                has_neutral_replica={search.has_neutral_replica}
                has_promoter_replica={search.has_promoter_replica}
                detractor_replica={question.detractor_replica}
                neutral_replica={question.neutral_replica}
                promoter_replica={question.promoter_replica}
              />
            );
          case "Resposta curta":
            return (
              <TextQuestionDefault
                description={question.description}
                label={`${
                  defaultPosition + index - countQuestionWithAnswer
                } - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                {...register(`quizResponses.${question.id}`)}
                selectColor={selectColor}
                error={errors[`quizResponses.${question.id}`]}
                setValue={(value: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                }}
                isRequired={question.required}
              />
            );
          case "Data":
            return (
              <DateQuestionDefault
                description={question.description}
                label={`${
                  defaultPosition + index - countQuestionWithAnswer
                } - ${question.name
                  .replace(/ Empresa ]/gi, replaceQuestion)
                  .replace("[", "")}`}
                {...register(`quizResponses.${question.id}`)}
                selectColor={selectColor}
                error={errors[`quizResponses.${question.id}`]}
                setValue={(value: string) => {
                  setValue(`quizResponses.${question.id}`, value);
                }}
                isRequired={question.required}
              />
            );
          default:
            return <></>;
        }
      })}

      <NpsAndSuggestion
        has_nps={!search.has_nps_first}
        nps_first={search.has_nps_first}
        nps_label={search.nps}
        register={register}
        selectColor={selectColor}
        setNpsValue={(nps: string, value: number) => {
          setValue("nps", value);
        }}
        setSuggestionValue={(suggestion: string) => {
          setValue("discursive_question", suggestion);
        }}
        suggestion_label={search.discursive_question}
        total_questions={questions.length - countQuestionWithAnswer}
      />

      <Flex justify="flex-end">
        <SubmitButton
          bg={company.secondary_color}
          color="white"
          text="Enviar"
          form="send-form"
          isLoading={formState.isSubmitting}
        />
      </Flex>
    </Flex>
  );
}
