import { Flex, Image, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { OutlineListButton } from "../../components/atoms/Button/OutlineListButton";
import { ExternalLink } from "../../components/atoms/Link/ExternalLink";
import { Footer } from "../../components/templates/Footer";
import { api } from "../../services/api";
interface WhiteLabel {
  name: string;
  logo_vertically_url: string;
  logo_horizontal_url: string;
  primary_color: string;
  form_domain: string;
  favicon_url: string;
}

export function Home() {
  const link = localStorage.getItem(`@falae:defaultLink`);
  const [whiteLabel, setWhiteLabel] = useState<WhiteLabel>();
  const navigate = useNavigate();
  useEffect(() => {
    if (link) {
      if (
        window.location.origin === process.env.REACT_APP_WEB_URL ||
        window.location.origin === process.env.REACT_APP_OTHER_WEB_URL
      ) {
        navigate(
          link
            .replace(process.env.REACT_APP_WEB_URL ?? "", "")
            .replace(process.env.REACT_APP_OTHER_WEB_URL ?? "", "")
        );
      } else if (whiteLabel) {
        navigate(link.replace(whiteLabel.form_domain, ""));
      }
    }
  }, [navigate, link, whiteLabel]);
  useEffect(() => {
    if (
      window.location.origin !== process.env.REACT_APP_WEB_URL &&
      window.location.origin !== process.env.REACT_APP_OTHER_WEB_URL
    ) {
      api
        .get(`/whiteLabels/formDomain?form_domain=${window.location.origin}`)
        .then((response) => {
          setWhiteLabel(response.data);
        });
    }
  }, [setWhiteLabel]);
  return (
    <Flex minH="100vh" bg="gray.100" flexDir="column">
      {whiteLabel ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Plataforma - {whiteLabel.name}</title>
          <link rel="icon" href={whiteLabel.favicon_url} />
          <link
            rel="manifest"
            href={`${process.env.REACT_APP_API_FORMULARIO_URL}/whiteLabels/formDomain/manifest?form_domain=${whiteLabel.form_domain}`}
          />
          <link rel="apple-touch-icon" href={whiteLabel.logo_vertically_url} />
        </Helmet>
      ) : (
        <Helmet>
          <title>Falaê - Plataforma</title>
          <link rel="icon" href="/favicon.ico" />
          <link rel="manifest" href="/manifest.json" />
          <link rel="apple-touch-icon" href="logo192.png" />
        </Helmet>
      )}
      <Flex alignItems="center" minH="95vh">
        <Flex
          flexDir="column"
          placeContent="center"
          align="center"
          width="100%"
        >
          <Flex display="flex" justify="center" align="center" flexDir="column">
            <Image
              src={
                window.location.origin !== process.env.REACT_APP_WEB_URL &&
                window.location.origin !== process.env.REACT_APP_OTHER_WEB_URL
                  ? whiteLabel?.logo_vertically_url
                  : "https://b2s.s3.sa-east-1.amazonaws.com/falae/logo_sem_fundo_vertical.png"
              }
              height={250}
              my={5}
              alt="Logo da Empresa"
            />
            {window.location.origin === process.env.REACT_APP_WEB_URL &&
              window.location.origin ===
                process.env.REACT_APP_OTHER_WEB_URL && (
                <Stack
                  alignItems="center"
                  flexDir="column"
                  justify="space-between"
                  my={5}
                >
                  <ExternalLink link="https://falae.app/">
                    <OutlineListButton color="#0088a1" text="Site" />
                  </ExternalLink>
                  <ExternalLink link="https://www.instagram.com/falae.app/">
                    <OutlineListButton color="#ff9000" text="Instagram" />
                  </ExternalLink>
                </Stack>
              )}
          </Flex>
        </Flex>
      </Flex>
      <Footer whiteLabel={whiteLabel} />
    </Flex>
  );
}
