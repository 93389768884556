import { Box, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { TitleDefault } from "../../../components/atoms/Text/TitleDefault";
import { Roulette } from "../../../components/Roulette";
import { useFormClient } from "../../../hooks/formClient";
import { api } from "../../../services/api";

interface Bonus {
  name: string;
  id: string;
}

export function IndexRoulette() {
  const { company, search } = useFormClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [bonusId, setBonusId] = useState("");
  const [couponId, setCouponId] = useState("");
  const [wheelloading, setWhelloading] = useState(false);
  const [bonuses, setBonuses] = useState<Bonus[]>([]);
  const { roulette_id, client_id } = useParams();

  const getPrizeIndex = useCallback(() => {
    if (!bonusId || bonuses.length === 0) return -1;
    return bonuses.findIndex((b) => b.id === bonusId);
  }, [bonusId, bonuses]);

  const handleSpinWheel = useCallback(async () => {
    try {
      const response = await api.post(`/clientForms/roulettes`, {
        client_id,
        roulette_id,
      });
      setCouponId(response.data.coupon_id);
      setBonusId(response.data.bonus_id);

      const prizeIndex = bonuses.findIndex(
        (b) => b.id === response.data.bonus_id
      );
      setPrizeNumber(prizeIndex !== -1 ? prizeIndex : 0);
      setMustSpin(true);
    } catch (error) {
      console.error("Erro ao determinar o prêmio:", error);
      setLoading(false);
    }
  }, [client_id, roulette_id, getPrizeIndex]);

  const handleStopSpinning = () => {
    setMustSpin(false);
    navigate(
      `/formularios/${company.slug}/${search.slug}/obrigado/${couponId}`
    );
  };

  useEffect(() => {
    setLoading(true);
    api
      .get(`/clientForms/roulettes/${roulette_id}/${client_id}`)
      .then((response) => {
        setBonuses(response.data);
        setLoading(false);
      })
      .catch(() => {
        navigate(`/formularios/${company.slug}/${search.slug}/obrigado`);
        setLoading(false);
      });
  }, [roulette_id, client_id]);

  return (
    <>
      <TitleDefault
        title={
          search.title
            ? search.title
            : `Pesquisa de Satisfação ${
                !search.is_enps ? `- ${company.name}` : ""
              }`
        }
      />

      {!loading && (
        <Box
          my={5}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={4}
        >
          {search.thanks_message ? (
            <HTMLText html={search.thanks_message} />
          ) : (
            <Text mt="50px">Sua resposta foi registrada com sucesso! </Text>
          )}
          <Roulette
            {...{
              handleStopSpinning,
              loading: wheelloading,
              mustSpin,
              prizeNumber,
              handleSpinWheel,
            }}
            bonuses={bonuses}
          />
        </Box>
      )}
    </>
  );
}
