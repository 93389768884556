import { Route, Routes as ReactRoutes } from "react-router-dom";

import { DegustContextProvider } from "../contexts/degustContext";
import { FormClientProvider } from "../hooks/formClient";
import { FormFidelityProgramProvider } from "../hooks/formFidelityProgram";
import { StoreClientProvider } from "../hooks/storeClient";
import { DegustCompaniesGroup } from "../pages/Degust/companies";
import { DegustEndControl } from "../pages/Degust/end";
import { DegustLoader } from "../pages/Degust/loader";
import { IndexFidelityProgram } from "../pages/FidelityProgram";
import { FidelityClient } from "../pages/FidelityProgram/client";
import { StoreFidelityClient } from "../pages/FidelityProgram/storeClient";
import { SuccessFidelityProgram } from "../pages/FidelityProgram/success";
import { Home } from "../pages/Home";
import { DefaultLinkConfig } from "../pages/Home/defaultLink";
import { StoreClientQrCodeOnePage } from "../pages/Searches/SearchOnePage/qrcode";
import { IndexAnsweredControl } from "../pages/Searches/SurveyOrganizer/indexAnswered";
import { IndexClosedControl } from "../pages/Searches/SurveyOrganizer/indexClosed";
import { IndexCompleteClientControl } from "../pages/Searches/SurveyOrganizer/indexCompleteClient";
import { IndexDisabledControl } from "../pages/Searches/SurveyOrganizer/indexDisabled";
import { IndexEndControl } from "../pages/Searches/SurveyOrganizer/indexEnd";
import { IndexLinktreeControl } from "../pages/Searches/SurveyOrganizer/indexLinktree";
import { IndexLoaderClientControl } from "../pages/Searches/SurveyOrganizer/indexLoaderClient";
import { IndexLoaderUnifyQrcodesFranchisesGroup } from "../pages/Searches/SurveyOrganizer/IndexLoaderUnifyQrcodesFranchisesGroup";
import { IndexLoaderUnits } from "../pages/Searches/SurveyOrganizer/indexLoaderUnits";
import { IndexPageControl } from "../pages/Searches/SurveyOrganizer/indexPage";
import { IndexRoulette } from "../pages/Searches/SurveyOrganizer/indexRoulette";
import { IndexSearchControl } from "../pages/Searches/SurveyOrganizer/indexSearch";
import { IndexStoreClientControl } from "../pages/Searches/SurveyOrganizer/indexStoreClient";
import { IndexUpdateClientControl } from "../pages/Searches/SurveyOrganizer/indexUpdateClient";
import { LoaderForm } from "../pages/Searches/SurveyOrganizer/loader";
import { StoreClient } from "../pages/StoreClient";
import { DisabledStoreClient } from "../pages/StoreClient/disabled";
import { EndStoreClient } from "../pages/StoreClient/end";
import { LoaderStoreClient } from "../pages/StoreClient/loader";
import { DegustTemplate } from "../template/Degust";
import { Fidelity } from "../template/FidelityProgram";
import { Form } from "../template/Search";
import { StoreClientTemplate } from "../template/StoreClient";

const ClientLayout = () => {
  return (
    <FormClientProvider>
      <Form>
        <ReactRoutes>
          <Route path="/:id" element={<LoaderForm />} />
          <Route
            path="/formularios/:slug/:search_slug/roleta/:roulette_id/:client_id"
            element={<IndexRoulette />}
          />
          <Route
            path="/formularios/:slug/:search_slug/pagina/inicial/:client_id?/:consumption_id?"
            element={<IndexPageControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/inicial"
            element={<LoaderForm />}
          />
          <Route
            path="/formularios/:slug/:search_slug/linktree"
            element={<IndexLinktreeControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/unidades/:unify_qrcode_id"
            element={<IndexLoaderUnits />}
          />
          <Route
            path="/formularios/:slug/:search_slug/grupos/:unify_qrcode_franchise_group_id"
            element={<IndexLoaderUnifyQrcodesFranchisesGroup />}
          />
          <Route
            path="/formularios/:slug/:search_slug/obrigado/:coupon_id?"
            element={<IndexEndControl />}
          />

          <Route
            path="/formularios/:slug/:search_slug/desativada"
            element={<IndexDisabledControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/encerrada"
            element={<IndexClosedControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/respondida"
            element={<IndexAnsweredControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/cliente/inicial/:answer_id?"
            element={<IndexLoaderClientControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/cliente/cadastro/:answer_id?"
            element={<IndexStoreClientControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/cliente/atualizar/:answer_id?"
            element={<IndexUpdateClientControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/cliente/completar/:client_id"
            element={<IndexCompleteClientControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/programa"
            element={<IndexFidelityProgram />}
          />
          <Route
            path="/formularios/:slug/:search_slug/:first_id?/:second_id?/:third_id?"
            element={<IndexSearchControl />}
          />
          <Route
            path="/formularios/:slug/:search_slug/cliente/qrcode/inicial/:answer_id?"
            element={<StoreClientQrCodeOnePage />}
          />
          <Route
            path="/pesquisa/:slug/respondida"
            element={<IndexAnsweredControl />}
          />
        </ReactRoutes>
      </Form>
    </FormClientProvider>
  );
};

const ClientFidelityProgram = () => {
  return (
    <FormFidelityProgramProvider>
      <Fidelity>
        <ReactRoutes>
          <Route
            path="/:slug/:search_slug"
            element={<IndexFidelityProgram />}
          />
          <Route
            path="/:slug/:search_slug/cliente"
            element={<FidelityClient />}
          />
          <Route
            path="/:slug/:search_slug/cliente/cadastro"
            element={<StoreFidelityClient />}
          />
          <Route
            path="/:slug/:search_slug/:client_id/sucesso/:coupon_id?"
            element={<SuccessFidelityProgram />}
          />
        </ReactRoutes>
      </Fidelity>
    </FormFidelityProgramProvider>
  );
};

const HomeLayout = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<Home />} />
    </ReactRoutes>
  );
};

const DegustLayout = () => {
  return (
    <DegustContextProvider>
      <DegustTemplate>
        <ReactRoutes>
          <Route path="/" element={<DegustLoader />} />
          <Route path="/:slug/obrigado" element={<DegustEndControl />} />
          <Route
            path="/:slug/unidades/:franchise_id/:search_invoice_id"
            element={<DegustCompaniesGroup />}
          />
        </ReactRoutes>
      </DegustTemplate>
    </DegustContextProvider>
  );
};

const IndependentCreateClientLayout = () => {
  return (
    <StoreClientProvider>
      <StoreClientTemplate>
        <ReactRoutes>
          <Route path="/:slug/inicial" element={<LoaderStoreClient />} />
          <Route path="/:slug/indisponivel" element={<DisabledStoreClient />} />
          <Route
            path="/:slug/obrigado/:coupon_id?"
            element={<EndStoreClient />}
          />
          <Route path="/:slug" element={<StoreClient />} />
        </ReactRoutes>
      </StoreClientTemplate>
    </StoreClientProvider>
  );
};
const Routes: React.FC = () => (
  <ReactRoutes>
    <Route path="/degust/*" element={<DegustLayout />} />
    <Route path="/programa-fidelidade/*" element={<ClientFidelityProgram />} />
    <Route path="/cadastro/*" element={<IndependentCreateClientLayout />} />
    <Route path="/config/redirecionar/padrao" element={<DefaultLinkConfig />} />
    <Route path="*" element={<ClientLayout />} />
    <Route path="/" element={<HomeLayout />} />
  </ReactRoutes>
);

export default Routes;
