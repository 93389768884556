import { Button, Flex } from "@chakra-ui/react";
import { Wheel } from "react-custom-roulette";

interface RoletteProps {
  bonuses: {
    name: string;
    id: string;
  }[];
  prizeNumber: number;
  mustSpin: boolean;
  loading: boolean;
  handleStopSpinning: () => void;
  handleSpinWheel: () => void;
}

export function Roulette({
  bonuses,
  prizeNumber,
  mustSpin,
  loading,
  handleStopSpinning,
  handleSpinWheel,
}: RoletteProps) {
  return (
    bonuses.length > 0 && (
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={bonuses.map((bonus) => {
            return {
              option: bonus.name,
            };
          })}
          backgroundColors={["#2b88a1", " #ff9000"]}
          outerBorderWidth={0}
          radiusLineWidth={1}
          textColors={["#ffffff"]}
          onStopSpinning={handleStopSpinning}
        />
        <Button
          position={"absolute"}
          zIndex={1000}
          rounded={"full"}
          width={20}
          height={20}
          onClick={handleSpinWheel}
          shadow={"md"}
          isLoading={loading}
          disabled={mustSpin ?? loading}
        >
          {mustSpin ? "Girando" : "Girar"}
        </Button>
      </Flex>
    )
  );
}
