import { Input, InputProps } from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";

interface UnstyledInputProps extends InputProps {
  name: string;
  id: string;
  isRequired?: boolean;
}

const InputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  UnstyledInputProps
> = ({ name, id, isRequired = false, ...rest }, ref) => {
  const currentYear = new Date().getFullYear();
  const maxDate = new Date();
  maxDate.setFullYear(currentYear + 100);
  const formattedMaxDate = maxDate.toISOString().split("T")[0];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).showPicker?.();
  };

  return (
    <Input
      type="date"
      variant="unstyled"
      w="calc(100% - 15px)"
      ml="10px"
      ref={ref}
      name={name}
      id={id}
      isRequired={isRequired}
      max={formattedMaxDate}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      {...rest}
    />
  );
};

export const DateInput = forwardRef(InputBase);
