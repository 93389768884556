import {
  Box,
  ChakraProvider,
  extendTheme,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

import { nextStep } from "../../../../utils/nextStep";
import { ClearButton } from "../../../atoms/Button/ClearButton";
import { FormErrorMessageDefault } from "../../../atoms/FormErrorMessage/FormErrorMessageDefault";
import { FormControlQuestionSearch } from "../../../molecules/FormControl/FormControlQuestionSearch";
import { InputGroup } from "../../../molecules/InputGroup";

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: { name: string; value?: any }[];
  label: string;
  name: string;
  setValue: (value: string) => void;
  description?: string;
  defaultValue?: string;
  isRequired: boolean;
  selectColor: string;
  other?: boolean;
  unknown?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | boolean;
  step?: number;
  setStep?: (value: number) => void;
  handleTrigger?: (value: string) => void;
  questionId?: string;
  updateError?: (value: string, error: boolean) => void;
  type?: string;
}

export function SelectQuestionDefault({
  options,
  label,
  name,
  setValue,
  selectColor,
  description,
  error,
  step,
  setStep,
  isRequired,
  handleTrigger,
  questionId,
  updateError,
  other,
  unknown,
  defaultValue,
  type,
}: IProps) {
  const [hasError, setHasError] = useState<boolean>(!!error);
  const [focus, setFocus] = useState<boolean>(false);
  const [selectOther, setSelectOther] = useState<boolean>(false);
  const [otherValue, setOtherValue] = useState<string>("");

  useEffect(() => {
    setHasError(!!error);
  }, [error]);

  const formatDefaultValue = useMemo(() => {
    if (defaultValue) {
      const checkIfOther = options.findIndex(
        (option) => option.name === defaultValue
      );

      if (checkIfOther === -1 && defaultValue !== "Não me recordo do nome") {
        setSelectOther(true);
        setOtherValue(defaultValue);
        return "Outro";
      }
      return defaultValue;
    }
    return "";
  }, [defaultValue, options]);

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    formatDefaultValue || ""
  );

  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      setSelectedValue(value);

      if (value === "Outro") {
        setSelectOther(true);
        setValue(otherValue);
      } else {
        setSelectOther(false);
        setValue(value === "" ? "" : value);
        setOtherValue("");
      }

      if (updateError && questionId) {
        updateError(`quizResponses.${questionId}`, !!error);
      }

      if (step !== undefined && setStep !== undefined) {
        const checkNextStep = nextStep({
          checkValue: value,
          type: type === "Atendente" ? "Atendente" : "Multipla escolha",
        });
        if (checkNextStep) {
          setStep(step + 1);
          handleTrigger?.(`quizResponses.${questionId}`);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setValue,
      otherValue,
      updateError,
      questionId,
      error,
      step,
      setStep,
      other,
      unknown,
      handleTrigger,
    ]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setOtherValue(value);
    setValue(value.trim() === "" ? "" : value);
  };

  const theme = extendTheme({
    components: {
      Select: {
        baseStyle: {
          field: {
            borderRadius: "4px",
            height: "58px",
            alignItems: "center",
            _hover: { borderColor: "gray.50" },
          },
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <FormControlQuestionSearch
        isRequired={isRequired}
        label={label}
        name={name}
        description={description}
      >
        {hasError && <FormErrorMessageDefault error="Questão obrigatória" />}
        <Box position="relative" mt="20px">
          <FormLabel
            as="legend"
            ml="10px"
            color={focus ? selectColor : "rgba(0, 0, 0, 0.6)"}
            fontSize="12px"
            lineHeight="16px"
            position="absolute"
            top="-2"
            zIndex={10}
            bg="white"
          >
            Selecione uma opção...
          </FormLabel>
          <Select
            boxShadow="none"
            _focus={{ borderColor: selectColor, boxShadow: "none" }}
            alignItems="center"
            onChange={handleSelect}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            value={selectedValue}
          >
            {((!selectedValue && isRequired) || !isRequired) && (
              <option value={undefined}></option>
            )}
            {options.map((option, index) => (
              <option key={index} value={option.name}>
                {option.name}
              </option>
            ))}
            {type === "Atendente" && unknown && (
              <option value={"Não me recordo do nome"}>
                Não me recordo do nome
              </option>
            )}
            {other && <option value={"Outro"}>Outro</option>}
          </Select>
          {other && selectOther && (
            <Box mt={5}>
              <InputGroup
                selectColor={selectColor}
                name={name}
                setValue={handleInputChange}
                defaultValue={otherValue}
                error=""
                isRequired={isRequired}
              />
            </Box>
          )}
        </Box>
      </FormControlQuestionSearch>
      {!isRequired && selectedValue && (
        <ClearButton
          onClick={() => {
            setValue("");
            setSelectedValue("");
            setSelectOther(false);
            setOtherValue("");
          }}
        />
      )}
    </ChakraProvider>
  );
}
