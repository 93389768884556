import { Checkbox, CheckboxGroup, Flex, Input, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";

import { SubmitButton } from "../../../components/atoms/Button/SubmitButton";
import { HTMLText } from "../../../components/atoms/Div/HTMLText";
import { Form } from "../../../components/atoms/Form";
import { BornDateInput } from "../../../components/atoms/Input/BornDateInput";
import { EmailInput } from "../../../components/atoms/Input/EmailInput";
import { PhoneInput } from "../../../components/atoms/Input/PhoneInput";
import { PrivacityText } from "../../../components/atoms/Text/PrivacityText";
import { SubtitleDefault } from "../../../components/atoms/Text/SubtitleDefault";
import { TitleDefault } from "../../../components/atoms/Text/TitleDefault";
import { FormControlMaterialUI } from "../../../components/templates/FormControlMaterialUI";
import { Loader } from "../../../components/templates/Loader";
import { useFormClient } from "../../../hooks/formClient";
import { api } from "../../../services/api";
import { phoneNumberValidation } from "../../../utils/phoneValidation";

const createClientFormData = yup.object().shape({
  email: yup.string().when("has_email", {
    is: (has_email: boolean) => !has_email,
    then: (s) =>
      s
        .required("O E-mail é obrigatório")
        .email("Formato inválido")
        .min(6, "Número de caracteres muito pequeno"),
    otherwise: (s) => s.notRequired(),
  }),
  phone: yup.string().when("has_phone", {
    is: (has_phone: boolean) => !has_phone,
    then: (s) =>
      s
        .required("O whatsapp é obrigatório")
        .test(
          "is-valid-phone",
          "Número de telefone inválido",
          phoneNumberValidation
        ),
    otherwise: (s) => s.notRequired(),
  }),
  born_date: yup.string().when("has_born_date", {
    is: (has_born_date: boolean) => !has_born_date,
    then: (s) =>
      s
        .required("A data de aniversário é obrigatória")
        .matches(
          /(0?[1-9]|[12]\d|30|31)\/(0[1-9]|1[0-2])\/(19[0-9]{2}|20[0-1][0-9])/,
          "Formato inválido "
        ),
    otherwise: (s) => s.notRequired(),
  }),
  approved: yup.boolean().required("Campo obrigatório"),
  has_email: yup.boolean().required("Campo obrigatório"),
  has_phone: yup.boolean().required("Campo obrigatório"),
  has_born_date: yup.boolean().required("Campo obrigatório"),
});

export function IndexCompleteClientControl() {
  const { search, company, isTest, whiteLabel } = useFormClient();
  const navigate = useNavigate();
  const { slug, search_slug, client_id } = useParams();
  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(createClientFormData),
  });

  const { register, handleSubmit, formState, watch, setValue } = form;

  const { errors } = formState;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    api.get(`/clientForms/completed/${client_id}`).then((response) => {
      setValue("has_email", response.data.email);
      setValue("has_born_date", response.data.born_date);
      setValue("has_phone", response.data.phone);
      if (response.data.completed) {
        navigate(`/formularios/${slug}/${search_slug}/obrigado`);
      }
      setIsLoaded(true);
    });
  }, [client_id, setValue]);

  const handleCompleteClient: SubmitHandler<FieldValues> = useCallback(
    async (formValue) => {
      if (!isTest) {
        const response = await api.put(`/clientForms/${client_id}`, {
          phone: formValue.phone,
          born_date: formValue.born_date,
          email: formValue.email,
          approved: formValue.approved,
        });

        localStorage.setItem(
          `@B2S-${company.slug}:user`,
          JSON.stringify({
            name: response.data.name,
            email: response.data.email,
            id: response.data.id,
          })
        );
        if (search.roulette_id) {
          navigate(
            `/formularios/${company.slug}/${search.slug}/roleta/${search.roulette_id}`
          );
          return;
        }

        if (response.data.coupons && response.data.coupons.length > 0) {
          navigate(
            `/formularios/${slug}/${search_slug}/obrigado/${response.data.coupons[0].id}`
          );
        } else {
          navigate(`/formularios/${slug}/${search_slug}/obrigado`);
        }
      }
    },
    [client_id, company.slug, navigate, slug, search_slug, isTest]
  );

  return (
    <>
      <TitleDefault title="Complete o seu cadastro" />
      <SubtitleDefault subtitle={company.name} />
      <HTMLText html={search.client_message} />
      <Stack spacing={2}>
        <Loader isLoading={isLoaded} whiteLabel={whiteLabel}>
          <Form
            id="complete-client"
            onSubmit={handleSubmit(handleCompleteClient)}
          >
            <Stack spacing={2}>
              {!watch("has_email") && (
                <FormControlMaterialUI
                  text="E-mail"
                  register={register}
                  name="email"
                  isRequired={true}
                  error={errors.email}
                  bg={company.color}
                  input={EmailInput}
                  is_totem={search.is_totem}
                />
              )}
              {!watch("has_phone") && (
                <FormControlMaterialUI
                  text="Whatsapp"
                  name="phone"
                  register={register}
                  isRequired={true}
                  bg={company.color}
                  error={errors.phone}
                  input={PhoneInput}
                  default_country={company.default_country}
                />
              )}

              {!watch("has_born_date") && (
                <FormControlMaterialUI
                  text="Data de aniversário"
                  register={register}
                  name="born_date"
                  isRequired={false}
                  error={errors.born_date}
                  bg={company.color}
                  input={BornDateInput}
                />
              )}
            </Stack>
            <CheckboxGroup>
              <Flex alignItems="start" justify="start" flexDir="column">
                <Checkbox
                  {...register("approved")}
                  isRequired={true}
                  variant=""
                >
                  <PrivacityText link={company.privacy_policy_link} />
                </Checkbox>
              </Flex>
              <Input required={true} type="hidden" />
            </CheckboxGroup>
          </Form>
        </Loader>
      </Stack>

      <Flex justify="flex-end" alignItems="center">
        <SubmitButton
          bg={company.color}
          color="white"
          text="Cadastrar"
          form="complete-client"
          isLoading={formState.isSubmitting}
        />
      </Flex>
    </>
  );
}
